import { getResult } from "utils/request";

export interface QueryParams {
  recommend_position?: number; // 推荐位置 1-包皮过长 2-早泄 3-勃起功能 4-开放访问 5-训练日记
  type?: string; // 列表类型 new-最新 hot-热门 cate-分类 topic-圈子 personal-个人主页 ucenter-个人中心
  cate_id?: number;
  topic_id?: number; //  圈子编号
  user_id?: number; //  用户编号
  subject_id?: number; // 特色专题编号
  page?: number;
  limit?: number;
}

export interface QueryCommentParams {
  // post_id?: number; // 帖子编号
  posts_id?: number; // 帖子编号
  page?: number;
  limit?: number;
}

export interface PostData {
  topic_id: number; //圈子编号
  title?: string; //帖子标题
  content?: string; //帖子内容
  images?: string[]; // 帖子图片
  post_type?: number; //帖子类型 1.普通帖子 2.日记 3.手术分享贴
  active_id?: number; // 日记ID 帖子类型为2时需要传
  rec_id?: number; // 加入日记记录ID 帖子类型为2时需要传
  item_day?: number; // 当前加入天数，需要根据加入天数计算 帖子类型为2时需要传
  item_name?: string; // 当前阶段名称 帖子类型为2时需要传
  curr_item_id?: number; // 当前阶段ID 帖子类型为2时需要传
  city_name?: string; // 帖子类型为3时需要传
  hospital_name?: string; // 帖子类型为3时需要传
  doctor_info?: string; // 帖子类型为3时需要传
  surgery_time?: string; // 帖子类型为3时需要传
  surgery_type?: string; // 帖子类型为3时需要传
  service_price?: string; // 帖子类型为3时需要传
  service_description?: string; // 帖子类型为3时需要传
}


/**
 * 获取当前用户关注的帖子
 *
 * @param page
 * @param limit
 */
const getUserFollowedPosts = (page?: number, limit?: number) => {
  return getResult({
    needLogin: true,
    url: `/api/sns/subscribe`,
    params: {
      recommend_position: 4,
      page,
      limit,
    },
  });
};


/**
 * 查询帖子「？」
 */
const getPostListForTopic = (params: QueryParams) => {
  return getResult({
    url: `/v2/community/topic/post_list`,
    params,
  });
};
/**
 * 获取帖子列表【新】
 * @param params 参数
 * @returns
 */
const queryPostList = (params: QueryParams) =>{
  return getResult({
    url: `/v2/community/posts/list`,
    params,
  });
};

/**
 * 帖子详情
 * @param post_id 帖子ID
 */
const getPostDetails = (post_id: number | string) => {
  return getResult({
    url: `/v2/community/posts/${post_id}`,
  });
};

/**
 * 获取评论列表
 * @param params
 */
const getComments = (params: QueryCommentParams) => {
  return getResult({
    url: `/v2/community/commentary`,
    params,
  });
};

/**
 * 用户删除自己的评论
 * @param data 提交数据
 */
const deleteComment = (data: {
  comment_id: number; // 评论编号
}) => {
  return getResult({
    needLogin: true,
    needRealInfo: true,
    url: `/v2/community/commentary/del`,
    data,
    method: "post",
  });
};

/**
 * 普通用户删帖
 */
const deletePost = (data: { posts_id: number }) => {
  return getResult({
    needLogin: true,
    needRealInfo: true,
    url: `/v2/community/posts/del`,
    data,
  });
};

/**
 * 发帖
 *
 * @param data 提交内容
 */
const release = (data: PostData) => {
  return getResult({
    needLogin: true,
    needRealInfo: true,
    url: `/v2/community/posts/create`,
    method: "post",
    data,
  });
};

/**
 * 回贴/回复评论
 */
const postComment = (data: {
  posts_id?: number | string; //   帖子编号
  refer_id?: number | string; //  上级评论编号
  content: string; //  评论内容
}) => {
  return getResult({
    needLogin: true,
    needRealInfo: true,
    url: `/v2/community/commentary/reply_posts`,
    data,
    method: "post",
  });
};

/**
 * 用户操作帖子
 *
 * @param {int} post_id 帖子编号
 * @param {String} operation 操作类型 'subscribe'-关注 'approve'-赞 'collect'-收藏 'share'-分享
 */
const operation = (post_id: number, operation: string, type: string) => {
  return getResult({
    needLogin: true,
    needRealInfo: true,
    url: `/v2/member/collection`,
    data: {
      active_id: post_id,
      active_type: operation,
      type
    },
    method: "post",
  });
};

const postApi = {
  getUserFollowedPosts,
  queryPostList,
  getPostListForTopic,
  getPostDetails,
  deleteComment,
  getComments,
  deletePost,
  release,
  postComment,
  operation
};

export default postApi;
