import * as actionTypes from "./constants";

export interface AggregationProps {
  id: number;
  name: string;
  count: number;
  hot_count: number;
}

export interface HomeState {
  use: boolean;
  aggregation?: Array<any>;
  contentList?: any[];
}

const defaultState: HomeState = {
  use: false,
  aggregation: [],
  contentList: [],
};

export default (state = defaultState, action: any): HomeState => {
  switch (action.type) {
    case actionTypes.SET_USE_STATE:
      return { ...state, use: action.data };
    case actionTypes.SET_AGGREGATION_DATA:
      return { ...state, aggregation: action.data };
    case actionTypes.SET_RECOMMEND_DATA:
        return { ...state, contentList: action.data };
    default:
      return state;
  }
};
