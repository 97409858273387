import { Toast } from "antd-mobile";
import * as actions from "./actionCreators";
import topicApi from "api/topic";
import { TopicState } from "./reducer";
import postApi from "api/post";
import { handleResponseError } from "utils/request";
/**
 * 初始化圈子详情页数据
 * @param id 圈子ID
 */
export const initTopic = (id: number, callback?: Function) => {
  return async (dispatch: any) => {
    try {
      const topic = await topicApi.getTopicDetails(id);
      dispatch(actions.changeTopicDetails(topic));
      callback && callback();
    } catch (error) {
      callback && callback(error);
    } finally {
      setTimeout(() => {
        dispatch(loadPosts());
      });
    }
  };
};

/**
 * /topic
 * 关注圈子
 */
export const handleFollowTopic = () => {
  return async (dispatch: any, getState: any) => {
    const topic = getState().getIn(["community", "topic", "details"]);
    try {
      if (!topic) {
        throw Error("handleFollowTopic Error: undefined topic");
      }
      await topicApi.operation(topic.topic_id, "subscribe");
      dispatch(initTopic(topic.topic_id));
      Toast.info("操作成功", 2, undefined, false);
    } catch (error) {
      handleResponseError(error);
    }
  };
};

/**
 * 加载圈子下的帖子列表
 */
export const loadPosts = () => {
  return async (dispatch: any, getState: any) => {
    const limit = 10;
    const state: TopicState = getState().getIn(["community", "topic"]);
    const { page, details, posts } = state;
    if (!details) throw Error("圈子信息不存在");
    try {
      dispatch(actions.changeLoading(true));
      // const { data } = await postApi.getPostListForTopic({
      const { data } = await postApi.queryPostList({
        recommend_position: 4,
        type: "topic",
        topic_id: details.topic_id,
        page,
        limit,
      });
      if (page === 1 || !posts) {
        dispatch(actions.changePosts(data));
      } else {
        dispatch(actions.changePosts([...posts, ...data]));
      }
      dispatch(actions.changeHasMore(data.length === limit));
    } catch (error) {
      Toast.info(error);
    } finally {
      dispatch(actions.changeLoading(false));
    }
  };
};

/**
 * 上拉加载帖子列表
 */
export const loadMorePosts = () => {
  return (dispatch: any, getState: any) => {
    const { loading, hasMore, page } = getState().getIn(["community", "topic"]);
    if (loading || !hasMore) {
      return;
    }
    dispatch(actions.changeLoading(true));
    dispatch(actions.changePage(page + 1));
    setTimeout(() => {
      dispatch(loadPosts());
    });
  };
};

/**
 * 重新加载帖子列表
 */
export const reloadPosts = () => {
  return (dispatch: any) => {
    dispatch(actions.changeHasMore(true));
    dispatch(actions.changePage(1));
    setTimeout(() => {
      dispatch(loadPosts());
    });
  };
};
