import * as consts from "./constants";
export * from "./customActions";

export const changeTopicDetails = (data?: DDHY.SNSTopicDetails) => ({
  type: consts.SET_TOPIC_DETAILS,
  data: data,
});
export const changePosts = (data?: Array<DDHY.SNSPost>) => ({
  type: consts.SET_POSTS,
  data: data,
});
export const changePage = (data: number) => ({
  type: consts.SET_PAGE,
  data: data,
});
export const changeLoading = (data: boolean) => ({
  type: consts.SET_LOADING,
  data: data,
});
export const changeHasMore = (data: boolean) => ({
  type: consts.SET_HASMORE,
  data: data,
});

/**
 * 清除圈子页数据
 */
export const cleanTopic = () => {
  return (dispatch: any) => {
    dispatch(changeTopicDetails());
    dispatch(changePosts());
    dispatch(changePage(1));
    dispatch(changeHasMore(true));
  };
};
