import { getResult } from "utils/request";

const memberApi = {
  /**
   * 当前用户的关注用户列表
   */
  userFollowed(params: { page?: number; limit?: number }) {
    return getResult({
      needLogin: true,
      // url: `/api/sns/collection/user_list`,
      url: `/v2/member/collection/user_list`,
      params,
    });
  },

  /**
   * 用户详细信息
   * @param {int} member_id 用户ID
   */
  getDetails(member_id: string | number) {
    return getResult({
      url: `/api/sns/personal/${member_id}`,
    });
  },

  /**
   * 用户操作圈子
   * @param {int} member_id 用户ID
   * @param {String} operation 操作类型 'subscribe'-关注 'approve'-赞 'collect'-收藏 'share'-分享
   */
  operation(member_id: number, operation: string) {
    return getResult({
      needLogin: true,
      needRealInfo: true,
      url: `/api/sns/collection/user`,
      data: {
        active_id: member_id,
        active_type: operation,
      },
      method: "post",
    });
  },
};

export default memberApi;
