import { lazy } from "react";

const Square = lazy(() => import("./Square")); // 社区广场
const Topic = lazy(() => import("./Topic")); // 圈子详情
const Post = lazy(() => import("./Post")); // 帖子详情
const CreatePost = lazy(() => import("./Post/Create"));
const CommentPost = lazy(() => import("./Post/CommentPost"));
const Member = lazy(() => import("./Member")); // 帖子详情

const routes: DDHY.Route[] = [
  { path: "/community", title: "社区广场", component: Square },
  {
    path: "/topic/:id",
    title: "圈子详情",
    component: Topic,
    private: true,
  },
  {
    path: "/post/create",
    title: "发帖",
    component: CreatePost,
    private: true,
  },
  {
    path: "/post/comment",
    title: "评论",
    component: CommentPost,
    private: true,
  },
  {
    path: "/post/:id",
    title: "回答详情",
    private: true,
    component: Post,
  },

  {
    path: "/member/:id",
    title: "用户详情",
    component: Member,
  },
];

export { routes };
