import * as actionTypes from "./constants";

export interface ArticleState {
  articleDetails: any;
}

const defaultState: ArticleState = {
  articleDetails: undefined,
};

export default (state = defaultState, action: any) => {
  switch (action.type) {
    // Article
    case actionTypes.SET_DETAILS:
      return {
        ...state,
        articleDetails: action.data,
      };

    default:
      return state;
  }
};
