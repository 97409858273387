import articleApi from "api/article";
import * as constatnts from "./constants";
import { Toast } from "antd-mobile";
import { handleResponseError } from "utils/request";

export const changeDetails = (data?: any) => ({
  type: constatnts.SET_DETAILS,
  data,
});

/**
 * 页面初始化
 *
 * @comopnent Article
 * @param id 文章ID
 */
export const initArticle = (id: string | number, callback?: Function) => {
  return (dispatch: any) => {
    const pms = articleApi.getDetails(id);
    pms.then((result) => {
      dispatch(changeDetails(result));
      callback && callback();
    });
    pms.catch((error: any) => {
      callback && callback(error);
    });
  };
};

/**
 * 收藏文章
 *
 * @comopnent Article
 * @param callback 回调函数
 */
export const handleCollectionArticle = (callback?: Function) => {
  return async (d: any, getState: any) => {
    try {
      const { articleDetails } = getState().getIn(["classroom", "article"]);
      if (!articleDetails || !articleDetails.article_info) {
        Toast.info("获取文章信息失败！请刷新重试");
        return;
      }
      let { article_info } = articleDetails;
      await articleApi.userOperation(article_info.article_id, 'collect');
    } catch (error) {
      handleResponseError(error);
    } finally {
      callback && callback();
    }
  };
};

/**
 * 点赞文章
 *
 * @component Article
 * @param callback 回调函数
 */
export const handleSupportArticle = (item: any, callback: Function) => {
  return async (d: any, getState: any) => {
    try {
      const { articleDetails } = getState().getIn(["classroom", "article"]);
      if (!articleDetails || !articleDetails.article_info) {
        Toast.info("获取文章信息失败！请刷新重试");
        return;
      }
      let { article_info } = articleDetails;
      await articleApi.userSupport(article_info.article_id, item.active_id);
    } catch (error) {
      handleResponseError(error);
    } finally {
      callback();
    }
  };
};
