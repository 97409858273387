// 聊天信息轮询时间
const CHAT_POLLING_INTERVAL = 10000;

// 消息轮询时间
const MESSAGE_POLLING_INTERVAL = 10000;

// 允许发就医分享贴的圈子ID
const SHARE_TOPIC_ID = 31;

// 咨询状态 0待付款 1沟通中 2关闭 3待仲裁 4仲裁中 5咨询完成 6申请退款
const CONSULT_STATUS: {
  [key: number]: string;
} = {
  0: "待付款",
  1: "沟通中",
  2: "关闭",
  3: "待仲裁",
  4: "仲裁中",
  5: "咨询完成",
  6: "申请退款",
};
//预约状态 0待付款 1待医生确认 2待就诊 3已就诊 4待付尾款 5已评价 6拒绝预约 7取消预约 8申请退款 9关闭预约
const RESERVATION_STATUS: {
  [key: number]: string;
} = {
  0: "待付款",
  1: "待医生确认",
  2: "待就诊",
  3: "已就诊",
  4: "待付尾款",
  5: "已评价",
  6: "医生拒接",
  7: "已取消",
  8: "退款中",
  9: "已关闭",
};

/*
 *  以下是程序中url配置
 */

// 环境变量
const NODE_ENV = process.env.NODE_ENV;
// const NODE_ENV = 'product';
// logo图片地址
const LOGO = "https://mp.ddhaoyi.com/public/images/logo_640.png";
// 图片根域名
let IMG_ROOT = "http://img.ddhaoyi.com/";
// 接口根域名
let API_ROOT = "https://api.ddhaoyi.com";
// 项目根域名
let ROOT = "https://mp.ddhaoyi.com";

// APP下载地址
let APP_DOWNLOAD_URL = 'https://www.ddhaoyi.com/member_download/index.html';


// 微信授权后重定向的回调链接地址， 需要使用 urlEncode 对链接进行处理
let WX_AUTH_REDIRECT_URI = "https://mp.ddhaoyi.com/login";

// let WX_AUTH_REDIRECT_URI = "//cs.m.ddhaoyi.com/login";
// let ROOT = "//cs.m.ddhaoyi.com";

// if (NODE_ENV === "development") {
//   //IMG_ROOT = "http://img.ddhaoyi.com/";
//   //API_ROOT = "https://api.ddhaoyi.com";
//   WX_AUTH_REDIRECT_URI = "https://cs.m.ddhaoyi.com/login";
//   ROOT = "https://cs.m.ddhaoyi.com";
// }

if (NODE_ENV === "development") {
  // IMG_ROOT = "http://cs.img.ddhaoyi.com/";
  API_ROOT = "//cs.api.ddhaoyi.com";
  WX_AUTH_REDIRECT_URI = "//cs.m.ddhaoyi.com/login";
  ROOT = "//cs.m.ddhaoyi.com";
}

export {
  SHARE_TOPIC_ID,
  CHAT_POLLING_INTERVAL,
  MESSAGE_POLLING_INTERVAL,
  CONSULT_STATUS,
  RESERVATION_STATUS,
  NODE_ENV,
  LOGO,
  IMG_ROOT,
  API_ROOT,
  ROOT,
  WX_AUTH_REDIRECT_URI,
  APP_DOWNLOAD_URL,
};
