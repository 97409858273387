import { getResult } from "utils/request";

export interface QueryParams {
  recommend_position?: number; // 推荐位置 1-包皮过长 2-早泄 3-勃起功能 4-开放访问 5-训练日记
  page?: number;
  limit?: number;
  cate_id?: number;
  order?: "hot";
}

const topicApi = {

  /**
   * 圈子查询
   * @param params 查询条件
   */
  getTopicList(params: QueryParams) {
    return getResult({
      url: `/v2/community/topic/list`,
      params,
    });
  },

  /**
   * 圈子详细信息
   * @param topic_id 圈子ID
   */
  getTopicDetails(topic_id: number) {
    return getResult({
      url: `/v2/community/topic/${topic_id}`,
    });
  },

  /**
   * 用户操作圈子
   * @param  topic_id 圈子编号
   * @param  operation 操作类型 'subscribe'-关注 'approve'-赞 'collect'-收藏 'share'-分享
   */
  operation(
    topic_id: string | number,
    operation: "subscribe" | "approve" | "collect" | "share"
  ) {
    return getResult({
      needLogin: true,
      needRealInfo: true,
      url: `/api/sns/collection/topic`,
      data: {
        active_id: topic_id,
        active_type: operation,
      },
      method: "post",
    });
  },

  /**
   * 当前用户关注的圈子列表
   */
  userFollowed(params: { page?: number; limit?: number }) {
    return getResult({
      needLogin: true,
      // url: `/api/sns/collection/topic_list`,
      url: `/v2/member/collection/topic_list`,
      params,
    });
  },
};
export default topicApi;
