import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Flex, Icon as AntIcon } from "antd-mobile";
import { Link } from "react-router-dom";
import { Icon, AdvCardView } from "components/basic";
import "./index.scss";
import mainApi from "api/main";


/**
 * Banner
 */
interface BannerProps {
  searchBar?: boolean; // 是否显示搜索栏 默认true
}
const Banner: React.FC<BannerProps> = (props) => {
  const { searchBar = true } = props;

  const [loading, setLoading] = useState(false);
  const [advInfo, setAdvInfo] = useState();

  // 获取Banner广告数据
  const loadBannerAdData = useCallback(async () => {
    setLoading(true);
    try {
      const result = await mainApi.getAdvInfoByCode("adv_mp_home");
      setAdvInfo(result);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!advInfo) {
      loadBannerAdData()
    }
  }, [advInfo, loadBannerAdData]);

  // 屏幕宽度
  const screenWidth = window.screen.availWidth;


  return useMemo(() => {
    return (
      <div className="Component-Banner">
        {searchBar && <SearchBar />}
        {(loading || !advInfo) &&
          <Flex
            style={{ height: `${screenWidth / 2}px` }}
            align="center"
            justify="center"
          >
            <AntIcon type="loading" size="lg" />
          </Flex>
        }
        {advInfo && <AdvCardView data={advInfo} viewWidth={screenWidth} />}
      </div>
    );
  }, [advInfo, loading, screenWidth, searchBar]);
};

/**
 * Banner顶部的固定搜索栏组件
 */
const SearchBar: React.FC = () => {
  return (
    <div className="Banner-SearchBar">
      <Link to="/search">
        <div className="SearchBar">
          <Flex justify="center">
            <Icon size="14">sousuo</Icon>
            <span style={{ paddingLeft: "3px" }}>搜索</span>
          </Flex>
        </div>
      </Link>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  ...state.getIn(["main", "advInfo"]),
});

export default connect(mapStateToProps)(memo(Banner));

