import { Toast } from "antd-mobile";
import * as actions from "./actionCreators";
import mainApi from "api/main";
import aggregationApi from "api/aggregation";

/**
 * 首页初始化
 *
 * @param callback
 */
export const initHome = (callback?: Function) => {
  return async (d: any) => {
    d(actions.changeUseState(true));
    Toast.loading("正在加载", 0);

    Promise.all([
      asyncLoadAggregationInfo(),
      loadRecommendList(),
    ])
      .catch((error) => {
        Toast.info(error);
      })
      .finally(() => {
        Toast.hide();
        callback && callback();
      });

    /**
     * 异步加载首页特色专题数据
     */
    function asyncLoadAggregationInfo() {
      return new Promise((resolve, reject) => {
        aggregationApi.queryAggregationList().then((result)=>{
          d(actions.changeAggregation(result));
        }).catch((error) => {
          Toast.info(error);
          reject(error);
        })
        .finally(() => {
          resolve(undefined);
        });
      });
    }

    /**
     * 加载推荐内容
     */
    function loadRecommendList(){
      return new Promise((resolve, reject) => {
        mainApi.queryContentList().then((result) => {
          if(Array.isArray(result)){
            d(actions.changeRecommendList(result));
          }
        }).catch((error) => {
          Toast.info(error);
          reject(error);
        })
        .finally(() => {
          resolve(undefined);
        });
      });
    }
  };
};

export const initPage = () => {};
