import * as actionTypes from "./constants";
import { AggregationProps } from "./reducer";
export * from "./customActions";

export const changeUseState = (data: boolean) => ({
  type: actionTypes.SET_USE_STATE,
  data: data,
});
export const changeAggregation = (data?: Array<AggregationProps>) => ({
  type: actionTypes.SET_AGGREGATION_DATA,
  data: data,
});
export const changeRecommendList = (data?: any[]) => ({
  type: actionTypes.SET_RECOMMEND_DATA,
  data: data,
});
