import { getResult } from "utils/request";

// 订单查询参数
export interface QueryParams {
  page?: number;
  limit?: number;
  order_state?: number;
}

const orderApi = {
  /**
   * 查询订单列表
   */
  query(params: QueryParams) {
    return getResult({
      // url: "/order/user/list",
      url: "/v2/order/list",
      params,
    });
  },

  /**
   * 获取可用的支付方式
   */
  getUsefulPaymentType() {
    return getResult({
      url: "/payment/useful",
    });
  },

  /**
   * 订单申请退款
   */
  applyRefund(data: {
    refund_reason?: string; // 退款原因
    refund_reason_detail?: string; // 退款描述
    order_sn: string; // 支付单号
    refund_type?: number; // 申请取消类型 [1:普通取消 2:强制取消]
  }) {
    return getResult({
      needLogin: true,
      needRealInfo: true,
      method: "post",
      url: "/refund/submite",
      data,
    });
  },

  /**
   *  未付款的预约直接取消
   */
  cancleReservation(reservation_id: string | number) {
    return getResult({
      needLogin: true,
      needRealInfo: true,
      url: "/pay/pay/" + reservation_id,
      method: "delete",
    });
  },

  /**
   *  获取预约咨询包价格信息
   */
  getReservationChatPackageInfo() {
    return getResult({
      url: "/api/ask/package",
    });
  },

  /**
   *  支付第一步
   *
   *  生成围观内部订单号
   * @Deprecated 功能已废弃
   * @param {object} data
   */
  createOnlookPaySn(data: {
    from: "wx" | "android" | "ios" | "pc";
    doctor_id: number;
    consult_id: number;
  }) {
    return getResult({
      needLogin: true,
      needRealInfo: true,
      url: "/api/ask/order/onlook",
      data,
      method: "post",
    });
  },

  /**
   *  支付第一步
   *
   *  生成预约内部订单号
   *
   *
   * TODO: 参数列表有误，需要和后台接口校验一下
   * @param {object} data
   * ```
   * {
   * order_type: 'reserve',   // 订单类型
   * pay_amount: string|number; // 预约价格
   * item_id:  number; // 项目 ID
   * access_id:  number; // 医生账号 ID
   * from: "wx",  //  订单来源 wx, android, ios,pc
   * real_name: realName,
   * phone: memberPhone,
   * reserve_content:  string; // reqired string 预约描述内容
   * reserve_date: string; // 预约日期
   * reserve_time_area:  string; // 预约时间段
   * img: string; //string 预约描述图片
   * }
   * ```
   */
  createReservationPaySn(data: any) {
    return getResult({
      needLogin: true,
      needRealInfo: true,
      url: "/v2/order/create",
      data,
      method: "post",
    });
  },
  /**
   *  支付第一步
   *
   *  生成预约内部订单号
   * @param {object} data
   */
  createReserveNumberPaySn(data: {
    pay_amount: string; // 咨询包价格
    from: string; // int 订单来源 wx, android, ios,pc
    doctor_id: number; // 医生 ID
    reserve_id: number; // 项目 ID
    consult_num: number; // 咨询次数
  }) {
    return getResult({
      needLogin: true,
      needRealInfo: true,
      url: "/api/ask/order/reserve_num",
      data,
      method: "post",
    });
  },

  /**
   *  支付第一步
   *
   *  生成咨询内部订单号
   */
  createConsultPaySn(data: {
    order_type: string; // 订单类型
    pay_amount: string; // 价格
    consult_type: number; //   咨询类型 1-一问一答 2-单次咨询
    access_id: number;
    consult_content: string; // 正文
    consult_images?: string; // 图片列表
    tag_id: number;
    tag_name: string;
    from: string; // chr订单来源 wx, android, ios, pc, wap
  }) {
    return getResult({
      needLogin: true,
      needRealInfo: true,
      url: "/v2/order/create",
      data,
      method: "post",
    });
  },

  /**
   *  支付第一步
   *
   *  生成购买咨询次数内部订单号
   */
  createConsultNumberPaySn(data: {
    pay_amount: string; // 支付金额
    from?: "wx"; // string 订单来源 wx, android, ios,pc
    doctor_id: number; // 医生 ID
    consult_id: number; // 咨询 ID
    consult_num: number; //  购买咨询次数
  }) {
    return getResult({
      needRealInfo: true,
      needLogin: true,
      url: "/api/ask/order/consult_num",
      data,
      method: "post",
    });
  },

  /**
   * 支付第一步
   *
   * 生成购买文章内部支付单号
   * @Deprecated 功能已废弃
   */
  createArticlePaySn(data: {
    article_id: number; // 文章编号
    article_price: number; // 文章价格
    from: "wx"; // string 订单来源 wx, android, ios,pc
  }) {
    return getResult({
      needLogin: true,
      needRealInfo: true,
      url: "/api/classroom/article/buy",
      data,
      method: "post",
    });
  },
  /**
   *  支付第一步
   *
   *  生成购买专栏内部支付单号
   */
  createSpecialPaySn(data: {
    special_id: number; // 专题编号
    special_price: string; // 专题价格
    from: "wx"; //  订单来源 wx, android, ios,pc
  }) {
    return getResult({
      needLogin: true,
      needRealInfo: true,
      url: "/api/classroom/special/buy",
      data,
      method: "post",
    });
  },

  /**
   *  支付第二步
   *
   *  创建订单
   */
  createOrder(params: {
    pay_sn: string; // 内部支付单号
    payment_code?: string;
  }) {
    return getResult({
      needLogin: true,
      needRealInfo: true,
      url: "/v2/payment/pay",
      params,
    });
  },

  /**
   *  支付第三步
   *
   *  验证订单状态
   * }
   */
  validateOrder(pay_sn: string, payment_code: string) {
    return getResult({
      url: "/payment/wx_return",
      params: {
        pay_sn,
        payment_code,
      },
    });
  },

  /**
   * 获取订单详情
   * @param order_id
   */
  fetchOrderDetails(order_id: string | number) {
    return getResult({
      url: "/v2/order/detail",
      needLogin: true,
      params: {
        order_id,
      },
    });
  },

  // 获取收货地址列表
  fetchOrderAddressList(){
    return getResult({
      url: `/v2/address/list`,
    });
  },

  // 获取收货地址详情
  fetchOrderAddressDetail(addressId: number){
    return getResult({
      url: `/v2/address/${addressId}`,
    });
  },

  // 添加收货地址
  addOrderAddress(data:{
    member_name: string,
    address: string,
    phone: string,
    address_id: number,
    is_default: number,
  }){
    return getResult({
      url: `/v2/address/add`,
      data,
      method: "post",
    });
  },

  // 删除收货地址
  delOrderAddress(addressId: number){
    return getResult({
      url: `/v2/address/del/${addressId}`,
    });
  },

  // 兑换商品
  exchangeGoods(code: string){
    return getResult({
      url: "/v2/payment/redeem_prize",
      needLogin: true,
      params: {
        code,
      },
    });
  },

  // 根据活动获取兑换码
  loadExchangeCode(code_id: string, active_id: string){
    return getResult({
      url: "/v2/code/bind_detail",
      needLogin: true,
      params: {
        code_id,
        active_id
      },
    });
  },

  // 获取助力购待助力信息
  loadHelpGoInfo(help_order_id){
    return getResult({
      url: "/v2/help/help_schedule",
      needLogin: true,
      params: {
        help_order_id,
      },
    });
  },
  // 创建助力购助力关系
  createHelpGoRelation(help_order_id){
    return getResult({
      url: "/v2/help/help_bind",
      needLogin: true,
      params: {
        help_order_id,
      },
    });
  },

};

export default orderApi;
