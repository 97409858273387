import * as actionTypes from "./constants";

const defaultState = {
  wxSDK: false,
  banners: [],
  bannersLoading: false,

  // 所有未读消息统计
  unreadCount: null
};

export default (state = defaultState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_WX_JSSDK:
      return { ...state, wxSDK: action.data };
    case actionTypes.SET_BANNERS:
      return { ...state, banners: action.data };
    case actionTypes.SET_BANNERS_LOADING:
      return { ...state, bannersLoading: action.data };
    case actionTypes.SET_UNREAD_COUNT:
      return { ...state, unreadCount: action.data };
    case actionTypes.SET_AD_VIEW_INFO:
      return { ...state, advInfo: action.data };
    default:
      return state;
  }
};
