import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { getQueryVariable } from "utils/util";
import './index.scss';

const TopAppDownloadBar: React.FC = () => {

  const mountedRef = useRef(false);
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  });

  // const history = useHistory();
  let showBar = false;
  let isShare = false;
  const [siteStyle, setSiteStyle] = useState(false);

  // 处理分享链接
  let route = getQueryVariable('route');
  if(route && !showBar){
    showBar = true;
    isShare = true;
  }

  // 处理指定路由常驻
  let { pathname, search } = useLocation();
  const viewPathList = [
    '/training',
    '/test',
    '/doctor',
    '/hospitaldetail',
    '/project',
    '/aggregation',
    '/special',
    '/topic',
    '/article',
    '/post',
  ];
  // 忽略显示顶部横幅
  const ignoreList = [
    '/app/invite',
  ];

  let path = pathname.substring(0, pathname.indexOf('/',1));
  if(path.length === 0){
    path = pathname;
  }
  if(!showBar && viewPathList.includes(path)){
    showBar = true;
  }
  if(showBar && ignoreList.includes(pathname)){
    showBar = false;
  }

  window.addEventListener("scroll", (e) => {
    let offsetTop = document.documentElement.scrollTop
    if(mountedRef.current){
      if(offsetTop >= 120){
        setSiteStyle(true);
      }else{
        setSiteStyle(false);
      }
    }
  });

  const openAppPrePage = useCallback(() => {
    localStorage.setItem('app_page', '');
    if(isShare){
      localStorage.setItem('app_page', search);
    }
    // history.push('/app/invite');
    window.location.href = 'https://www.ddhaoyi.com/member_download/index.html';
  },[isShare, search]);

  if(showBar){
    return (
      <div className="appdownload-bar" onClick={openAppPrePage} style={{
        position: `${siteStyle?"fixed":"relative"}`
      }}>
        <div className="bar-main">
          <div className="ddhy-logo">
            <img src="https://res.ddhaoyi.com/common/logo/ddhy/40.png" alt="" />
          </div>
          <div className="download-text">
            <div className="app-name">下载丁丁好医APP享福利</div>
            <div className="app-desc">免费试看、视频课程、私密图文</div>
          </div>
          <div className="download-btn" onClick={openAppPrePage}>立即下载</div>
        </div>
      </div>
    );
  }else{
    return null;
  }
};

export default TopAppDownloadBar;


