import { getResult } from "utils/request";

const messageApi = {
  /**
   * 获取所有未读消息统计
   */
  countAllUnread() {
    return getResult({
      url: `/v2/member/message_count`,
    });
  },

  /**
   * 获取消息列表
   */
  loadMessageList(params: {page?: number; limit?: number}){
    return getResult({
      url: `/v2/message_list`,
      params
    });
  },

  /**
   * 获取医生对话消息列表
   * @param params
   */
  loadDoctorChatMeessageList(params: {type: number; page?: number; limit?: number}){
    return getResult({
      needLogin: true,
      url: `/v2/ask/chat_list`,
      params,
    });
  },

  /**
   * 获取社区点赞消息列表
   * @param params
   */
  loadSnsSupportMessageList(params: {page?: number; limit?: number}){
    return getResult({
      needLogin: true,
      url: `/v2/member/supported_mine`,
      params,
    });
  },

  /**
   * 获取社区评论消息列表
   * @param params
   */
  loadSnsCommentMessageList(params: {page?: number; limit?: number}){
    return getResult({
      needLogin: true,
      url: `/v2/member/commoned_mine`,
      params,
    });
  },

  /**
   * 获取用户通知列表
   * @param params
   */
  loadNoticeMessageList(params: {id: number; page?: number; limit?: number}){
    return getResult({
      needLogin: true,
      url: `/v2/member/message`,
      params,
    });
  },









  /**
   * 获取当前用户的点赞列表
   */
  getUserSupported(params: any): Promise<any> {
    return getResult({
      needLogin: true,
      url: `/api/sns/message/supportedmy`,
      params,
    });
  },

  /**
   * 获取当前用户的评论列表
   */
  getUserComment(params: any): Promise<any> {
    return getResult({
      needLogin: true,
      url: `/api/sns/message/commonedmy`,
      params,
    });
  },

  /**
   * 获取用户通知
   */
  getUserNotice(params: any) {
    return getResult({
      needLogin: true,
      url: `/api/ask/notify`,
      params,
    });
  },

  /**
   * 获取系统通知
   */
  getSystemNotice(params: any) {
    return getResult({
      needLogin: true,
      url: `/api/ask/message`,
      params,
    });
  },

  /**
   * 将消息标为已读
   * @param {int} message_id 消息ID
   */
  markNotifyAsRead(message_id: number | string) {
    return getResult({
      needLogin: true,
      url: `/api/ask/message/detail/${message_id}`,
    });
  },
};

export default messageApi;
